<template>
	<div class="row patient_chart third-party-main">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 px-0">
			<div class="third_prt_itgn">
				<div class="main_bx_con">

					<div class="d-flex align-items-center header_cn position-relative"
						:class="{ 'justify-content-between': this.isShowSrForm }" v-if="this.currentForm == 'srFax'">
						<h1 class="h1-admin_head mb-0">SRFax Account Settings (Incoming & Outgoing Faxes)</h1>
						<span class="popup_link ml-50" @click="showSRFaxForm" v-if="!this.isShowSrForm"
							:class="{ 'invisible': !this.checkPermission() }">Edit</span>
						
						<div class="mx15">
							<span class="popup_link mr-50" v-if="this.isShowSrForm" @click="cancelSrfax">Cancel</span>
							<span class="popup_link" v-if="this.isShowSrForm" @click="saveSRFax">Save
								<img src="images/loader.gif" v-if="this.srfaxloader" style="width: calc(var(--scale-ratio) * 18px);" />
							</span>
						</div>
					</div>

					<div class="d-flex align-items-center header_cn position-relative"
						:class="{ 'justify-content-between': this.isShowEditForm }" v-else>
						<h1 class="h1-admin_head mb-0">RingCentral Account Settings (Incoming & Outgoing
							Faxes)</h1>
						<span class="popup_link ml-50" @click="showRingCentralForm" v-if="!this.isShowEditForm"
							:class="{ 'invisible': !this.checkPermission() }">Edit</span>
						
						<div class="mx15">
							<span class="popup_link mr-50" v-if="this.isShowEditForm"
								@click="cancelRingCentral()">Cancel</span>
							<span class="popup_link" v-if="this.isShowEditForm" @click="saveRingCentral">Save
								<img src="images/loader.gif" v-if="this.ringCentralloader" style="width: calc(var(--scale-ratio) * 18px);" />
							</span>
						</div>
					</div>

					<div class="dtl_con" style="min-height: calc(var(--scale-ratio) * 438px);">
						<div class="srfax-account-box position-relative" v-if="this.currentForm == 'ringcentral' && this.isShowEditForm">
							<div class="srfax-account-dtls mb-10">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.SRFaxform.account_number }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center">
									<div class="srfax-label">
										<p class="mb-0">CONNECTION STATUS</p>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && this.isConnected">
										<div class="d-flex align-items-center">
											<span class="check-span">
												<img src="images/check-mark.svg">
											</span>
											<p class="mb-0 cnct-status-ttl">Connected</p>
										</div>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && !this.isConnected">
										<div class="d-flex align-items-center">
											<span class="clode-span">
												<img src="images/close-icon.svg">
											</span>
											<p class="mb-0 cnct-status-ttl text-red">Connection Failed</p>
										</div>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-center srfax-account-dtls my-0 bg-white py-0 pe-0">
								<span class="gry_color fs-20 fw-600 col-7 lh-base">Please login to your RingCentral account to connect / reconnect with the EMR</span>
								<button class="comman_btn crt-user-bttn big_btn_new">Login to RingCentral</button>
							</div>
						</div>
						<div class="srfax-account-box position-relative" v-if="this.currentForm == 'srFax' && this.isShowSrForm">
							<div class="srfax-account-dtls mb-10">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-edit w-100">
										<input type="number" placeholder="" class="form-control cursor-auto"
											v-model="SRFaxform.account_number">
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.SRFaxform.account_number.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">PASSWORD</p>
									</div>
									<div class="srfax-dtls-edit w-100">
										<div class="usr-dtls-inp position-relative">
											<input :type="inputPasswordType" class="form-control w-100 cursor-auto"
												v-model="SRFaxform.password">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer"
												v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else
												@click="changePasEle('desc')">
										</div>
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.SRFaxform.password.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">NOTIFICATION EMAIL</p>
									</div>
									<div class="srfax-dtls-edit w-100">
										<input type="email" placeholder="" class="form-control cursor-auto"
											v-model="SRFaxform.notification_email">
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.SRFaxform.notification_email.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">RESPONSE FAX NUMBER</p>
									</div>
									<div class="srfax-dtls-edit w-100">
										<input type="text" placeholder="" class="form-control cursor-auto"
											v-model="this.SRFaxform.response_fax_number"
											@input="formatPhoneNumber('response_fax_number')">
										<div class="position-absolute">
											<ValidationErrorMessageList
												:errors="v$.SRFaxform.response_fax_number.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">COVER PAGE</p>
									</div>
									<div class="cover_page_drop-select">
										<CustomDropDown :options="coverPageOpt" :initialValue="SRFaxform.cover_letter"
											v-model="SRFaxform.cover_letter" @item-selected="handleItemSelected"
											fieldName="cover_letter" divClass="cover-page-box"></CustomDropDown>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">CONNECTION STATUS</p>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && this.isConnected">
										<div class="d-flex align-items-center mr-37">
											<span class="check-span">
												<img src="images/check-mark.svg">
											</span>
											<p class="mb-0 cnct-status-ttl">Connected</p>
										</div>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && !this.isConnected">
										<div class="d-flex align-items-center mr-37">
											<span class="clode-span">
												<img src="images/close-icon.svg">
											</span>
											<p class="mb-0 cnct-status-ttl text-red">Connection Failed</p>
										</div>
									</div>
									<!-- <div class="status-show popper-tips-hvr d-none">
										<div class="d-flex align-items-center">
											<span class="question-span">
												<img src="images/question-mark.svg">
											</span>
											<p class="mb-0 cnct-status-ttl">Success</p>
										</div>
									</div> -->
								</div>
								<div class="d-flex align-items-center ">
									<div class="srfax-label">
										<p class="mb-0">CURRENT PLAN</p>
									</div>
									<div class="srfax-dtls-edit">
										<div class="d-flex align-items-center">
											<input type="number" placeholder="" class="form-control cursor-auto w-90p mr-15"
												value="500">
											<div class="lh-sm fw-600">Pages / Month</div>
										</div>
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.SRFaxform.account_number.$errors" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="srfax-account-box position-relative" v-if="this.currentForm == 'ringcentral' && !this.isShowEditForm">
							<div class="srfax-account-dtls mb-10">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.SRFaxform.account_number }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center">
									<div class="srfax-label">
										<p class="mb-0">CONNECTION STATUS</p>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && this.isConnected">
										<div class="d-flex align-items-center">
											<span class="check-span">
												<img src="images/check-mark.svg">
											</span>
											<p class="mb-0 cnct-status-ttl">Connected</p>
										</div>
									</div>
									<div class="status-show popper-tips-hvr"
										v-if="this.isConnected != null && !this.isConnected">
										<div class="d-flex align-items-center">
											<span class="clode-span">
												<img src="images/close-icon.svg">
											</span>
											<p class="mb-0 cnct-status-ttl text-red">Connection Failed</p>
										</div>
									</div>
								</div>
							</div>
							<div class="text-end">
								<button class="comman_brdr_btn big_btn" v-if="!this.isShowEditForm" style="padding: calc(var(--scale-ratio) * 17px) calc(var(--scale-ratio) * 29px);width: calc(var(--scale-ratio) * 270px);" @click="switchtoSRForm">Switch to SRFax</button>
							</div>
						</div>
						<div class="srfax-account-box position-relative" v-if="this.currentForm == 'srFax' && !this.isShowSrForm">
							<div class="srfax-account-dtls mb-10">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.SRFaxform.account_number }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">PASSWORD</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">********</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">NOTIFICATION EMAIL</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.SRFaxform.notification_email }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">RESPONSE FAX NUMBER</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.SRFaxform.response_fax_number }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">COVER PAGE</p>
									</div>
									<div class="cover-page-box">
										<p class="mb-0">{{ this.SRFaxform.cover_letter }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">CONNECTION STATUS</p>
									</div>
									<div class="status-show popper-tips-hvr d-flex justify-content-between w-100"
										v-if="this.isConnected != null && this.isConnected">
										<div class="d-flex align-items-center">
											<span class="check-span">
												<img src="images/check-mark.svg">
											</span>
											<p class="mb-0 cnct-status-ttl">Connected</p>
										</div>
									</div>
									<div class="status-show popper-tips-hvr d-flex justify-content-between w-100"
										v-if="this.isConnected != null && !this.isConnected">
										<div class="d-flex align-items-center">
											<span class="clode-span">
												<img src="images/close-icon.svg">
											</span>
											<p class="mb-0 cnct-status-ttl text-red">Connection Failed</p>
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center ">
									<div class="srfax-label">
										<p class="mb-0">CURRENT PLAN</p>
									</div>
									<div class="srfax-dtls-show d-flex align-items-center">
										<p class="mb-0 w-90p">500</p>
										<div class="lh-sm fw-600 fs-18">Pages / Month</div>
									</div>
								</div>
								<div class="curnt_usage"><span>current usage: </span> {{this.currentUsage}} Pages ({{this.currentUsagePer}}%)</div>
							</div>
							<div class="text-end">
								<button class="comman_brdr_btn big_btn" v-if="!this.isShowSrForm" style="padding: calc(var(--scale-ratio) * 17px) calc(var(--scale-ratio) * 27px);width: calc(var(--scale-ratio) * 270px);" @click="switchtoRingCenter"> Switch to RingCentral </button>
							</div>
						</div>
					</div>
				</div>
				<div class="main_bx_con">
					<div class="d-flex align-items-center header_cn"
						:class="{ 'justify-content-between': this.isShowStannpForm }">
						<h2 class="h1-admin_head mb-0">Stannp Account Settings</h2>
						<span class="popup_link ml-50" @click="showStannpForm" v-if="!this.isShowStannpForm"
							:class="{ 'invisible': !this.checkPermission() }">Edit</span>
						<div class="mx15">
							<span class="popup_link mr-50" v-if="this.isShowStannpForm"
								@click="cancelStannp">Cancel</span>
							<span class="popup_link" v-if="this.isShowStannpForm" @click="saveStannpForm">Save <img
									src="images/loader.gif" v-if="this.stannploader" style="width: calc(var(--scale-ratio) * 18px);" /></span>
						</div>
					</div>
					<div class="dtl_con">
						<div class="srfax-account-box position-relative" v-if="this.isShowStannpForm">
							<div class="srfax-account-dtls">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-edit w-100 position-relative">
										<input type="number" placeholder="" class="form-control cursor-auto"
											v-model="stannpForm.account_number">
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.stannpForm.account_number.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">PASSWORD</p>
									</div>
									<div class="srfax-dtls-edit w-100 position-relative">
										<div class="usr-dtls-inp position-relative">
											<input :type="stannpinputPasswordType" class="form-control w-100 cursor-auto"
												v-model="stannpForm.password">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer"
												v-if="this.stannpinputPasswordType != 'password'"
												@click="changePasStannpEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else
												@click="changePasStannpEle('desc')">
										</div>
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.stannpForm.password.$errors" />
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center">
									<div class="srfax-label">
										<p class="mb-0">API Key</p>
									</div>
									<div class="srfax-dtls-edit w-100 position-relative">
										<div class="usr-dtls-inp position-relative">
											<input :type="stannpinputApiKeyType" class="form-control w-100 cursor-auto"
												v-model="stannpForm.apikey">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer"
												v-if="this.stannpinputApiKeyType != 'password'"
												@click="changeApiKeyStannpEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else
												@click="changeApiKeyStannpEle('desc')">
										</div>
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.stannpForm.apikey.$errors" />
										</div>
									</div>
									<!-- <div class="srfax-dtls-show">
										<p class="mb-0">********</p>
									</div> -->
								</div>
							</div>
						</div>
						<div class="srfax-account-box position-relative" v-if="!this.isShowStannpForm">
							<div class="srfax-account-dtls">
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">ACCOUNT NUMBER</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">{{ this.stannpForm.account_number }}</p>
									</div>
								</div>
								<div class="d-flex align-items-center mb-30">
									<div class="srfax-label">
										<p class="mb-0">PASSWORD</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">********</p>
									</div>
								</div>
								<div class="d-flex align-items-center">
									<div class="srfax-label">
										<p class="mb-0">API Key</p>
									</div>
									<div class="srfax-dtls-show">
										<p class="mb-0">********</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import axios from "@/scripts/axios.js";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
import useVuelidate from "@vuelidate/core";

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			healthCardEligible: 'Health Card Passed Validation',
			healthCardclose: 'Health Card Does not Exists/ Invalid ',
			isShowSrForm: false,
			isShowStannpForm: false,
			srFaxData: [],
			coverPageOpt: [
				{ value: "Not Set", title: "Not Set" },
				{ value: "Basic", title: "Basic" },
				{ value: "Standard", title: "Standard" },
				{ value: "Company", title: "Company" },
				{ value: "Personal", title: "Personal" },
			],
			srfaxloader: false,
			isPasswordEnc: true,
			inputPasswordType: 'password',
			stannpinputPasswordType: 'password',
			stannpinputApiKeyType: 'password',
			stannploader: false,
			isConnected: null,
			isShowEditForm: false,
			currentForm: 'srFax',
			ringCentralloader: false,
			currentUsage:0,
			currentUsagePer:0
		}
	},
	components: {
		sidebar,
		CustomDropDown,
		ValidationErrorMessageList
	},
	methods: {
		showSRFaxForm() {
			this.inputPasswordType = 'password'
			this.isPasswordEnc = true
			this.isShowStannpForm = false
			this.isShowSrForm = true
		},
		cancelSrfax() {
			this.fetchSRFaxRecords()
			this.isShowSrForm = false
		},
		showStannpForm() {
			if (this.currentForm == 'srFax') {
				this.isShowSrForm = false
			} else {
				this.isShowEditForm = false
			}
			this.isShowStannpForm = true
			this.stannpinputPasswordType = 'password'
			this.stannpinputApiKeyType = 'password'

		},
		cancelStannp() {
			this.fetchStannpRecord()
			this.isShowStannpForm = false
		},
		fetchSRFaxRecords() {
			this.$store.state.loader = true;
			axios.post('admin/fax-config/list')
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						if (response.data.data) {
							this.$store.state.fax.form = response.data.data;
						} else {
							this.$store.state.fax.form = this.$store.state.user_site_details.defaultFormData;
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		},
		handleItemSelected(values) {
			this.SRFaxform[values.fieldName] = values.item.value
		},
		saveSRFax() {
			let formName = 'SRFaxform';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.srfaxloader = true;

				let url = ''
				if (this.SRFaxform.id) {
					url = "admin/fax-config/update"
				} else {
					url = "admin/fax-config/save"
				}

				axios.post(url, this.SRFaxform)
					.then((response) => {
						this.srfaxloader = false;
						if (response.status == 200) {
							this.fetchConnectionStatus()
							this.$filters.moshaToast("Third-Party Integrations has been updated successfully", "success");
							this.cancelSrfax()
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.srfaxloader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
			} else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}

				return false

			}

		},
		changePasEle(action) {

			if (action == 'enc') {
				this.isPasswordEnc = true
				this.inputPasswordType = 'password'
			} else {
				this.isPasswordEnc = false
				this.inputPasswordType = 'text'
			}
		},
		changePasStannpEle(action) {
			if (action == 'enc') {
				this.stannpinputPasswordType = 'password'
			} else {
				this.stannpinputPasswordType = 'text'
			}
		},
		changeApiKeyStannpEle(action) {
			if (action == 'enc') {
				this.stannpinputApiKeyType = 'password'
			} else {
				this.stannpinputApiKeyType = 'text'
			}
		},
		formatPhoneNumber(fieldName) {
			let phoneNumber = null
			if (fieldName == 'response_fax_number') {
				phoneNumber = this.SRFaxform.response_fax_number
			}
			// // Remove all non-numeric characters
			let cleaned = ('' + phoneNumber).replace(/\D/g, '');

			// // Match the cleaned string to the desired format
			let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

			if (match) {
				phoneNumber = `${match[1]}-${match[2]}-${match[3]}`;
			} else {
				phoneNumber = cleaned;
			}

			if (fieldName == 'response_fax_number') {
				this.SRFaxform.response_fax_number = phoneNumber
			}
		},
		fetchStannpRecord() {
			this.$store.state.loader = true;
			axios.post('admin/user-stannp/retrieve')
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						if (!response.data.data) {
							this.$store.state.user_stannp.form = this.$store.state.user_stannp.defaultFormData;
						} else {
							this.$store.state.user_stannp.form = response.data.data;
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		},
		saveStannpForm() {
			let formName = 'stannpForm';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.stannploader = true;

				let url = ''
				if (this.stannpForm.id) {
					url = "admin/user-stannp/update"
				} else {
					url = "admin/user-stannp/save"
				}

				axios.post(url, this.stannpForm)
					.then((response) => {
						this.stannploader = false;
						if (response.status == 200) {
							this.$filters.moshaToast("Third-Party Integrations has been updated successfully", "success");
							this.cancelStannp()
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.srfaxloader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
			} else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}

				return false

			}

		},
		getPermissionList() {
			axios.post('user/get-permission')
				.then((response) => {
					if (response.status == 200) {
						this.permissionList = response.data.data
					} else {
						this.permissionList = []
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.permissionList = []
					} else {
						this.permissionList = []
					}
				});
		},
		checkPermission() {
			if (!this.permissionList) {
				this.getPermissionList()
			}
			if (this.permissionList && this.permissionList.administration_access) {
				return true
			} else {
				return false
			}
		},
		async fetchConnectionStatus() {
			axios.post("admin/fax-config/check-status")
				.then((response) => {
					this.stannploader = false;
					if (response.status == 200) {
						if (response.data.data == 'true' || response.data.data) {
							this.isConnected = true
							this.getCurrentUsage()
						} else {
							this.isConnected = false
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.isConnected = false
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		},
		switchtoRingCenter() {
			this.isShowSrForm = false
			this.isShowEditForm = false
			this.currentForm = 'ringcentral'
		},
		switchtoSRForm() {
			this.isShowSrForm = false
			this.isShowEditForm = false
			this.currentForm = 'srFax'
		},
		showRingCentralForm() {
			this.isShowEditForm = true
		},
		cancelRingCentral() {
			this.isShowEditForm = false
		},
		saveRingCentral() {
			this.isShowEditForm = false
		},
		getCurrentUsage(){
			axios.post('inbox/get-fax-usage')
				.then((response) => {
					if (response.status == 200) {
						let detail = response.data.data
						if (detail) {
							this.currentUsage = detail.NumberOfPages
							this.currentUsagePer = ((this.currentUsage / 500) * 100).toFixed(2)
							this.saveCurrentUsage()
							
						}
					}
				}).catch(error => {
				if (error.response.status === 422) {
					console.log(error.response)
				} else {
					console.log(error.response)
				}
			});
		},
		saveCurrentUsage(){
			let url = "admin/fax-config/update"
			axios.post(url, {id:this.SRFaxform.id, plan_usage_per_month:this.currentUsage})
				.then((response) => {
					this.srfaxloader = false;
					if (response.status == 200) {
						console.log("Current usage saved")
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.srfaxloader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		}
	},
	mounted() {
		this.getPermissionList()
		this.fetchSRFaxRecords()
		this.fetchStannpRecord()
		this.fetchConnectionStatus()
	},
	computed: {
		SRFaxform() {
			return this.$store.state.fax.form;
		},
		stannpForm() {
			return this.$store.state.user_stannp.form;
		}
	},
	validations() {
		return {
			SRFaxform: this.$store.state.fax.validationRules.form,
			stannpForm: this.$store.state.user_stannp.validationRules.form,
		}
	},
};
</script>