<template>
	<div class="row patient_chart third-party-main">
		<sidebar></sidebar>
    </div>
</template>

<script>
import sidebar from './sidebar.vue'
export default {
	data(){
		return{

        }
    },
    components:{
		sidebar,
	},
    methods:{
    }
}
</script>